import { object } from 'prop-types';
import * as styles from './choose.module.scss';
import { removingWordFree } from '../../helpers';
import { Wrap } from '../sections-wrap';

function Choose({ data }) {
  return (
    <div className={styles.chooseContainer}>
      <Wrap>
        <div className={styles.header}>
          <h2 className={styles.title}>{removingWordFree(data?.eaPracticeStep2ContentTitle)}</h2>
        </div>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: removingWordFree(data?.eaPracticeStep2Content) }}
        />
      </Wrap>
    </div>
  );
}

Choose.propTypes = {
  data: object,
};

Choose.defaultProps = {
  data: {},
};

export { Choose };
