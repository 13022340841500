import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { Section } from '../components/common/practice-child-step/section';
import { Step } from '../components/step-section';
import { LegalJourney } from '../components/legal-journey';
import { Choose } from '../components/legal-journey-choose';
import { LawyersSection } from '../components/lawyers-section';
import * as styles from './templates.module.scss';

function PracticeChildStep2Template({ data }) {
  const stepData = data?.wpPage?.wpParent?.node;
  const lawyersTitle = stepData.wpParent?.node?.title;
  const currentUri = stepData.uri;
  const lawyersData = data?.wpPage?.wpParent?.node?.wpParent?.node?.practiceToTeamConnection?.nodes;
  return (
    <main className={styles.stepPageStyles}>
      <Section data={stepData} />
      <Step step={2} text="Choose your lawyer" />
      <LegalJourney steps={4} currentStep={2} uri={currentUri} />
      <Choose data={data?.wpPage?.template?.practiceStepConfigurationAllSteps} />
      {!!lawyersData.length && <LawyersSection title={lawyersTitle ? `${lawyersTitle} Lawyers` : 'Lawyers'} data={lawyersData} />}
    </main>
  );
}

export const query = graphql`
  query wpChildStepTemplate2($uri: String) {
  wpPage(uri: {eq: $uri}) {
      title
      uri
      template {
        ... on WpTemplate_PracticeStep {
          practiceStepConfigurationAllSteps {
            eaPracticeStep2Content
            eaPracticeStep2ContentTitle
          }
        }
      }
      wpParent {
        node {
          ... on WpPage {
            uri
            template {
              ... on WpTemplate_PracticeChild {
                practiceChildConfiguration {
                  eaPracticeShortName
                }
              }
            }
            wpParent {
              node {
                ... on WpPage {
                  title
                  uri
                  practiceToTeamConnection {
                    nodes {
                      title
                      uri
                      featuredImage {
                        node {
                          gatsbyImage(width:400)
                        }
                      }
                      attorneyTeamSingleConfiguration {
                        eaTeamSpecialty
                        eaTeamQuickBio
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      ...SeoPage
    }
  }
`;

PracticeChildStep2Template.propTypes = {
  data: object,
};

PracticeChildStep2Template.defaultProps = {
  data: {},
};

export default PracticeChildStep2Template;
export { Head } from '../components/seo/seo';
