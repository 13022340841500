import { number, string } from 'prop-types';
import { Wrap } from '../sections-wrap';
import * as styles from './step-section.module.scss';

function Step({ step, text }) {
  return (
    <Wrap>
      <main className={styles.main} role="main">
        <article className={styles.entry}>
          <header>
            <div className={styles.titleContainer}>
              <h2 className={styles.title}>
                Step
                {' '}
                {step}
              </h2>
              <h1 className={styles.additional}>{text}</h1>
            </div>
          </header>
        </article>
      </main>
    </Wrap>
  );
}

Step.defaultProps = {
  step: 0,
  text: '',
};

Step.propTypes = {
  step: number,
  text: string,
};

export { Step };
